<template>
  <div id="cms-cash-count-create">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Cash Count - Add</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div class="col-sm-9" style="color: white; text-align: right">
            <b-row>
              <b-col class="col-sm-5" style="margin-left: 6em">
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Date:"
                        label-for="date"
                >
                  <b-form-input id="date" type="date" v-model="cashCount.date" style="margin-left: 2em"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€200:"
                      label-for="two-hundred"
              >
                <b-form-input id="two-hundred" type="number" v-model="cashCount.two_hundred" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€2.00:"
                      label-for="two"
              >
                <b-form-input id="two" type="number" v-model="cashCount.two" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€100:"
                      label-for="hundred"
              >
                <b-form-input id="hundred" type="number" v-model="cashCount.hundred" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€1.00:"
                      label-for="one"
              >
                <b-form-input id="one" type="number" v-model="cashCount.one" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€ 50 :"
                      label-for="fifty"
              >
                <b-form-input id="fifty" type="number" v-model="cashCount.fifty" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.50:"
                      label-for="fifty_cent"
              >
                <b-form-input id="fifty_cent" type="number" v-model="cashCount.fifty_cent" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€ 20 :"
                      label-for="twenty"
              >
                <b-form-input id="fifty" type="number" v-model="cashCount.twenty" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.20:"
                      label-for="twenty_cent"
              >
                <b-form-input id="twenty_cent" type="number" v-model="cashCount.twenty_cent" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€ 10 :"
                      label-for="ten"
              >
                <b-form-input id="ten" type="number" v-model="cashCount.ten" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.10:"
                      label-for="ten_cent"
              >
                <b-form-input id="ten_cent" type="number" v-model="cashCount.ten_cent" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label=" € 5 :"
                      label-for="five"
              >
                <b-form-input id="five" type="number" v-model="cashCount.five" @change="updateTotalCashCount()" style="margin-left: 0.2em" ></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.05:"
                      label-for="five_cent"
              >
                <b-form-input id="five_cent" type="number" v-model="cashCount.five_cent" @change="updateTotalCashCount()" style="margin-left: 0.2em"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-col class="col-sm-5">
                <b-form-group
                        label-cols-sm="6"
                        label-cols-lg="6"
                        label=" Total Cash Count:"
                        label-for="total"
                >
                  <b-form-input
                          id="total"
                          type="number"
                          v-model="cashCount.total"
                          style="margin-left: 0.2em"
                          disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <router-link class="link-color" to="/cms/cash-count" @click.prevent>
                  <b-button type="reset" variant="danger">Cancel</b-button>
                </router-link>
                <b-button  @click.prevent="addCashCount()" type="submit" variant="success">Submit</b-button>
              </b-col>
              <b-col class="col-sm-4"></b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'
  import axios from "axios";

  export default {
    data() {
      return {
        cashCount:
          {
            date: '',
            five_cent: null,
            ten_cent: null,
            twenty_cent: null,
            fifty_cent: null,
            one: null,
            two: null,
            five: null,
            ten: null,
            twenty: null,
            fifty: null,
            hundred: null,
            two_hundred: null,
            five_hundred: null,
            total: 0.00.toFixed(2)
          },

      }
    },
    components: {
      SideMenu
    },
    methods: {
      getTotalCashCount() {
        let total = 0.00

        total += this.cashCount.five_cent ? (0.05 * this.cashCount.five_cent) : 0
        total += this.cashCount.ten_cent ? (0.10 * this.cashCount.ten_cent) : 0
        total += this.cashCount.twenty_cent ? (0.20 * this.cashCount.twenty_cent) : 0
        total += this.cashCount.fifty_cent ? (0.50 * this.cashCount.fifty_cent) : 0
        total += this.cashCount.one ? (1.00 * this.cashCount.one) : 0
        total += this.cashCount.two ? (2.00 * this.cashCount.two) : 0
        total += this.cashCount.five ? (5.00 * this.cashCount.five) : 0
        total += this.cashCount.ten ? (10.00 * this.cashCount.ten) : 0
        total += this.cashCount.twenty ? (20.00 * this.cashCount.twenty) : 0
        total += this.cashCount.fifty ? (50.00 * this.cashCount.fifty) : 0
        total += this.cashCount.hundred ? (100.00 * this.cashCount.hundred) : 0
        total += this.cashCount.two_hundred ? (200.00 * this.cashCount.two_hundred) : 0
        total += this.cashCount.five_hundred ? (500.00 * this.cashCount.five_hundred) : 0

        return total.toFixed(2)
      },
      updateTotalCashCount() {
        this.cashCount['total'] = this.getTotalCashCount()
      },
      addCashCount() {
        this.axios
            .post(
                'https://batavia-backend.herokuapp.com/api/cash-count',
                {
                  "date": this.cashCount.date,
                  "five_cent": this.cashCount.five_cent ? this.cashCount.five_cent : 0,
                  "ten_cent": this.cashCount.ten_cent ? this.cashCount.ten_cent : 0,
                  "twenty_cent": this.cashCount.twenty_cent ? this.cashCount.twenty_cent : 0,
                  "fifty_cent": this.cashCount.fifty_cent ? this.cashCount.fifty_cent : 0,
                  "one": this.cashCount.one ? this.cashCount.one : 0,
                  "two": this.cashCount.two ? this.cashCount.two : 0,
                  "five": this.cashCount.five ? this.cashCount.five : 0,
                  "ten": this.cashCount.ten ? this.cashCount.ten : 0,
                  "twenty": this.cashCount.twenty ? this.cashCount.twenty : 0,
                  "fifty": this.cashCount.fifty ? this.cashCount.fifty : 0,
                  "hundred": this.cashCount.hundred ? this.cashCount.hundred : 0,
                  "two_hundred": this.cashCount.two_hundred ? this.cashCount.two_hundred : 0,
                  "five_hundred": this.cashCount.five_hundred ? this.cashCount.five_hundred : 0,
                  "total": this.cashCount.total
                },
                {
                  headers: {
                    Authorization: axios.defaults.headers.common.Authorization
                  }
                }
            )
            .then(() => {
              this.$router.push({ name: 'CmsCashCounts' })
            })
            .catch(error => {
              console.log(error)
            })
      }
    }
  }
</script>